import React, { Component } from 'react'
import {Link} from "react-router-dom";

export class Navbar extends Component {
  static propTypes = {}

  render() {
    return (
    <nav className="navbar navbar-expand-lg navbar-dark ">
    <a className="navbar-brand" href="/">Home</a>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav">
        <li className="nav-item ">
        <Link className="nav-link"  to="/projects">Projects</Link>
          
        </li>
        <li className="nav-item ">
          <Link className="nav-link"  to="/contact" >Contact Me</Link>
          
        </li>
      </ul>
    </div>
  </nav>
    )
  }
}

export default Navbar