import React, { Component } from 'react'
import Skills from './Skills'

export default class Profile extends Component {
  render() {
    return (
        
      <div className="row">
      <div className=" col-md-6 text-center">
        <div className="mx-auto ml-5">
          <img 
            className="profile_pic"
            alt=""
            src={require("../images/headshot.png")}
          />
        </div>
      </div>

        <div className=" col    mx-auto skills mt-5">
          <Skills />
        </div>

      <div className="row mx-auto">
        <div className="col md-6 sm-6 mx-auto mb-5">
          <div className="bio mt-4  text-center">
            <p>
              Thank you very much for checking out my portfolio site and I’m
              glad you’re here.
            </p>
            <p>
              My name is Isaac Martin and I am a Full Stack Developer from Jacksonville Florida. My areas of focus are HTML5, CSS, React, and Javascript followed closely by  Bootstrap 4.
            </p>
            <p>
              Before becoming a developer, I spent around 21 years in various IT related roles from networking to computer repair/troubleshooting. 
            </p>
            <p>If you would like to get in touch with me
             please use the "Contact Me" link at the top and fill out the form.<br></br> There are also links to my LinkedIn profile and Github at the bottom right.
             <p> <br></br>
             Thanks for stopping by and have a great day! 
             </p>
            
            </p><a href='https://github.com/isaacamartin/' alt='' rel="noreferrer" target={"_blank"}><img 
            className="social"
            alt=""
            src={require("../images/app-icon-github.png")}
          /></a><a href='https://www.linkedin.com/in/isaac-a-martin/' alt="" rel="noreferrer" target={"_blank"}><img 
            className="social"
            alt=""
            src={require("../images/LinkedIn.png")}
          /></a>
            
          </div>
        </div>{/* 
        <div className="col-md">
          
        
        </div> */}
      </div>
    </div>
    )
  }
}