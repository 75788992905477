import React, { Component } from "react";

export class ContactForm extends Component {
  render() {
    return (
      
      <form className="form" action="https://formsubmit.co/isaacamartin@outlook.com"  method="POST" >
        <p>First Name</p>
        <input className="input " type="text" name="First Name" required></input>
        <p>Last Name</p>
        <input className="input " type="text" name="Last Name" required></input>
        <p>Email Addresss</p>
        <input className="" type="email" name="Email" required></input>
        <p>Message</p>
        <textarea className="form_text" rows="3" type="text" name="Message" required></textarea>
        <p></p>
        <button type="submit">Send</button>
        <input type="hidden" name="_subject" value="Comment Box"></input>
        <input type="hidden" name="_captcha" value="false"></input>
        <input type="hidden" name="_next" value="https://isaacmartinsdotcom.com/thanks"></input>
      </form>
    
    );
  }
}

export default ContactForm;
