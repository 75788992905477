import React, { Component } from 'react'

export class Footer extends Component {
  static propTypes = {}

  render() {
    return (
      <div className=' footer'>
        <p className='footer_text'>&copy;2022 Layout and Design: Isaac Martin</p>
      </div>
    )
  }
}

export default Footer