import React, { Component } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";

export class Projects extends Component {
  static propTypes = {};

  render() {
    return (
      <div className="container">
        <div className="row topnav">
          <div className="col">
            <Navbar />
          </div>
        </div>
        <div className="row">
          <div className=" col blue_top">
            <div className="welcome_text mx-auto">
            <h3 className="">Welcome To The Projects Page</h3>
            <h5 className="mt-4">Below you will find a few of the projects that I've built.</h5>
            <h5 className="mt-4">If you click on them, you'll be taken to an interactive version!</h5></div>
          </div>
          <div className="row pb-5">
            <div className="col pb-4">

            <div className="picholder text-center">
              <div className="inline_element">
                
                <a href="https://personalwebsite.isaacmartinsdotcom.com" rel="noreferrer" target={"_blank"}><img
                className="gallery_pic"
                alt=""
                src={require("../images/personalwebsite_res.jpg")}
              /></a>
              
              
              <a href="https://id-card.isaacmartinsdotcom.com" rel="noreferrer" target={"_blank"}><img
                className="gallery_pic"
                alt=""
                src={require("../images/id_card_res.jpg")}
              /></a>
              <a href="https://fan-page.isaacmartinsdotcom.com" rel="noreferrer" target={"_blank"}><img
                className="gallery_pic"
                alt=""
                src={require("../images/fan_page_res.jpg")}
              /></a>
              
              <a href="https://counter-app.isaacmartinsdotcom.com" rel="noreferrer" target={"_blank"}><img
                className="gallery_pic"
                alt=""
                src={require("../images/counter_app_res.jpg")}
              /></a>
              <a href="https://typescript-todo-list.isaacmartinsdotcom.com" rel="noreferrer" target={"_blank"}><img
                className="gallery_pic"
                alt=""
                src={require("../images/typescript_todo_list_res.jpg")}
              /></a>
              
              <a href="https://weaponry.isaacmartinsdotcom.com" rel="noreferrer" target={"_blank"}><img
                className="gallery_pic"
                alt=""
                src={require("../images/weaponry.jpg")}
              /></a></div>
            </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className=" col text-center">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default Projects;
