import React, { Component } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";

export class Thanks extends Component {
  render() {
    return (
      <div className="container">
        <div className="row topnav">
          <div className="col">
            <Navbar />
          </div>
        </div>
        <div className="row">
          <div className="col blue_top">
            
        <div className="welcome_text mx-auto ">
            <h2 className=" mt-1">Thank You!</h2>
            <h5 className="mt-4">Suggestions and constructive criticism <br></br> are always welcome.</h5></div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            
          </div>
        </div>
        <div className="row backsplash_footer">
          <div className=" col text-center">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default Thanks;