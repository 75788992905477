import React, { Component } from 'react'

export class Skills extends Component {
  static propTypes = {}

  render() {
    return (
      <div>
      <div className='row'>
        <div className='col'>
        <div>
            <h4 className=''><strong><u>Skill Levels</u></strong></h4><br></br>
            <h4>CSS </h4>
            <h4>HTML5</h4>
            <h4>Bootstrap 4</h4>
            <h4>React</h4>
            <h4>Javascript</h4>
            <h4>Anguler</h4></div>
         </div>
         <div className='col'>
          <div className='mt-1'>
          <h4><br></br><br></br>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked "></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star not_checked"></span></h4>
          <h4>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked "></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star not_checked"></span></h4>
          <h4>
<span class="fa fa-star checked"></span>
<span class="fa fa-star checked "></span>
<span class="fa fa-star checked"></span>
<span class="fa fa-star not_checked"></span>
<span class="fa fa-star not_checked"></span></h4>
          <h4>
<span class="fa fa-star checked"></span>
<span class="fa fa-star  checked"></span>
<span class="fa fa-star not_checked"></span>
<span class="fa fa-star not_checked"></span>
<span class="fa fa-star not_checked"></span></h4>
          <h4>
<span class="fa fa-star checked"></span>
<span class="fa fa-star  checked"></span>
<span class="fa fa-star not_checked"></span>
<span class="fa fa-star not_checked"></span>
<span class="fa fa-star not_checked"></span></h4>
          <h4>
<span class="fa fa-star checked"></span>
<span class="fa fa-star  checked"></span>
<span class="fa fa-star not_checked"></span>
<span class="fa fa-star not_checked"></span>
<span class="fa fa-star not_checked"></span></h4></div>
         </div>
      </div>
      </div>
    )
  }
}

export default Skills