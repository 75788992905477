import React, { Component } from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/Footer';
import Profile from '../components/Profile';


export class Titlepage extends Component {
  render() {
    return (
        <div className="container">
          <div className="row">
            <div className="col topnav">
              <Navbar />
            </div>
          </div>
          <div className='content-area mb-5'>
          <Profile/>
          </div>
          
          <div className="row backsplash_footer">
            <div className=" col text-center">
              <Footer />
            </div>
          </div>
        </div>
    )
  }
}

export default Titlepage