import "./App.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Titlepage from "./screens/Titlepage";
import Projects from "./screens/projects";
import Contact from "./screens/Contact";
import Thanks from "./screens/Thanks";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Titlepage />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/thanks" element={<Thanks />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
