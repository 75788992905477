import React, { Component } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

export class Contact extends Component {
  render() {
    return (
      <div className="container">
        <div className="row topnav">
          <div className="col">
            <Navbar />
          </div>
        </div>
        <div className="row">
          <div className="col contact blue_top">
            
        <div className="welcome_text mx-auto ">
            <h2 className=" mt-1">Drop me a line!</h2>
            <h5 className="mt-4">Feel like contacting me directly?<br></br> This is the place to do it!</h5></div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ContactForm  />
          </div>
        </div>
        <div className="row backsplash_footer">
          <div className=" col text-center">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
